import { getAddress, keccak256 } from 'ethers'
import { defaultAbiCoder } from '@ethersproject/abi'

import BlastMainnetFewWrappedTokenJSON from '../constants/abis/BlastMainnetFewWrappedToken.json'
import EVMIFewWrappedTokenJSON from '../constants/abis/EVMFewWrappedToken.json'
import BlastIFewWrappedTokenJSON from '../constants/abis/FewWrappedToken.json'
import { isAddress } from 'utils'

type ChainConfig = {
  factory: string
  bytecode: string
}

const FEW_WRAPPED_FACTORY_CONFIGS: { [key: number]: ChainConfig } = {
  [168587773]: {
    factory: '0xf11788d14EbE6abF4EA02e162C75AD938F1730C1',
    bytecode: BlastIFewWrappedTokenJSON.bytecode,
  },
  [81457]: {
    factory: '0x455b20131D59f01d082df1225154fDA813E8CeE9',
    bytecode: BlastMainnetFewWrappedTokenJSON.bytecode,
  },
  [11155111]: {
    factory: '0x226e65279E177A779522864Ce1dE40c85E2C08A5',
    bytecode: EVMIFewWrappedTokenJSON.bytecode,
  },
}

const FEW_WRAPPED_TOKEN_FACTORY_ADDRESS = (chainId: number): string => {
  if (!(chainId in FEW_WRAPPED_FACTORY_CONFIGS)) {
    return FEW_WRAPPED_FACTORY_CONFIGS[81457].factory
  } else {
    return FEW_WRAPPED_FACTORY_CONFIGS[chainId].factory
  }
}

const FEW_WRAPPED_TOKEN_BYTECODE = (chainId: number): string => {
  if (!(chainId in FEW_WRAPPED_FACTORY_CONFIGS)) {
    return FEW_WRAPPED_FACTORY_CONFIGS[81457].bytecode
  } else {
    return FEW_WRAPPED_FACTORY_CONFIGS[chainId].bytecode
  }
}

export function getWrappedTokenCreate2Address(token: string, chainId: number): string {
  if (!isAddress(token)) return ''
  const constructorArgumentsEncoded = defaultAbiCoder.encode(['address'], [token])
  const create2Inputs = [
    '0xff',
    FEW_WRAPPED_TOKEN_FACTORY_ADDRESS(chainId),
    // salt
    keccak256(constructorArgumentsEncoded),
    // init code. bytecode + constructor arguments
    keccak256(FEW_WRAPPED_TOKEN_BYTECODE(chainId)),
  ]
  const sanitizedInputs = `0x${create2Inputs.map((i) => i.slice(2)).join('')}`
  return getAddress(`0x${keccak256(sanitizedInputs).slice(-40)}`)
}
